<template>
  <div class="new-transport">
    <div v-if="allCarsData" class="row data">
      <div class="col">
        <InfoBlock :all-cars-data="allCarsData" />
      </div>
      <div class="col-6">
        <SliderBlock :all-cars-data="allCarsData" />
      </div>
      <div class="col">
        <CharacteristicBlock :all-cars-data="allCarsData" />
      </div>
    </div>
    <div v-if="allCarsData" class="row stats">
      <div class="col" style="padding-left: 0;">
        <StatisticBlock :all-cars-data="allCarsData" />
      </div>
      <div class="col" style="padding-right: 0;">
        <CostBlock :all-cars-data="allCarsData" />
      </div>
    </div>
    <div v-if="allCarsData" class="works">
      <WorkingTansportBlock :all-cars-data="allCarsData" />
    </div>
    <div v-if="allCarsData" class="row stats">
      <div class="col" style="padding-left: 0;">
        <MaintenanceBlock :all-cars-data="allCarsData" />
      </div>
      <div class="col" style="padding-right: 0;">
        <MaintenanceBlock :all-cars-data="allCarsData" :table="'history'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    InfoBlock: () => ({
      component: import("@/components/info/infoTransport"),
    }),
    CharacteristicBlock: () => ({
      component: import("@/components/info/infoCharacteristic"),
    }),
    SliderBlock: () => ({
      component: import("@/components/info/infoImageSlider"),
    }),
    StatisticBlock: () => ({
      component: import("@/components/info/infoStatistic"),
    }),
    CostBlock: () => ({
      component: import("@/components/info/infoCost"),
    }),
    WorkingTansportBlock: () => ({
        component: import('@/components/info/workingTransport')
    }),
    MaintenanceBlock: () => ({
        component: import('@/components/info/infoMaintenance')
    }),
    
  },
  data() {
    return {
      allCarsData: null,
    };
  },
  methods: {
    async transportSave() {
      let formData = new FormData();
      if (this.addTransport) formData = this.addTransport;
      this.$store.dispatch("postAddTransport", formData);
    },
    async infoData() {
      this.$api
        .get("/web/transport/show/" + this.$route.params.id)
        .then((response) => {
          this.allCarsData = response.data.data
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, попробуйте позднее!');
        });
    },
  },
  computed: {
    ...mapState(["addTransport", "allCars"]),
  },
  async mounted() {
    // await this.$store.dispatch("getAllCars");
    await this.infoData();
  },
};
</script>

<style scoped>
.new-transport__block {
  margin-top: 20px;
}
.data{ 
  background: #FFFFFF;
  border-radius: 20px;
}
.stats {
  margin-top: 20px;
  margin-bottom: 20px;
}
.works {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 20px;
}
</style>
